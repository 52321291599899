import React, { useState } from 'react'
import styled from 'styled-components'
import { toCssPrefix, Loading } from '@ecommerce/shared'
import secrets from '../../config/secrets'

type Props = React.HTMLAttributes<HTMLDivElement>

const { BEETRACK_API_KEY } = secrets

const { cssPrefix, toPrefix } = toCssPrefix('TrackingWidget__')
const Wrapper = styled.div`
  position: relative;
  min-height: 84px;

  .${cssPrefix} {
    &loader {
      width: 84px;
      height: 84px;
      position: absolute;
      top: calc(50% - 42px);
      left: calc(50% - 42px);
      z-index: 0;
    }

    &widget {
      width: 100%;
      border: none;
    }
  }
`

const TrackingWidget = ({ className = '', ...props }: Props) => {
  const [loading, setLoading] = useState(true)

  return (
    <Wrapper className={`${cssPrefix} ${className}`} {...props}>
      {loading && <Loading className={toPrefix('loader')} />}
      <iframe
        className={toPrefix('widget')}
        onLoad={() => setLoading(false)}
        src={`https://app.beetrack.com/widget/${BEETRACK_API_KEY}`}
        title="Beetrack widget"
      />
    </Wrapper>
  )
}

export default TrackingWidget
